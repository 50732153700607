import * as React from "react"
import jsonp from 'jsonp';
import { navigate } from 'gatsby'
import birthdayHeroJohnGoncalves from '../images/landing/birthday-hero-john-goncalves.webp'

/**
 * Make a jsonp request to user's mailchimp list
 *  `param` object avoids CORS issues
 *  timeout to 3.5s so user isn't waiting forever
 *  usually occurs w/ privacy plugins enabled
 *  3.5s is a bit longer than the time it would take on a Slow 3G connection
 *
 * @param {String} url - concatenated string of user's gatsby-config.js
 *  options, along with any MC list fields as query params.
 *
 * @return {Promise} - a promise that resolves a data object
 *  or rejects an error object
 */

 const subscribeEmailToMailchimp = ({ url, timeout }) =>
  new Promise((resolve, reject) =>
      jsonp(url, { param: 'c', timeout }, (err, data) => {
          if (err) reject(err);
          if (data) resolve(data);
      }),
 );

/**
* Build a query string of MC list fields
*
* @param {Object} fields - a list of mailchimp audience field labels
*  and their values. We uppercase because that's what MC requires.
*  NOTE: GROUPS stay as lowercase (ex: MC uses group field names as `group[21269]`)
*
* @return {String} - `&FIELD1=value1&FIELD2=value2&group[21265][2]=group1`
*/
const convertListFields = fields => {
  let queryParams = '';
  for (const field in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, field)) {
          // If this is a list group, not user field then keep lowercase, as per MC reqs
          // https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/README.md#groups
          const fieldTransformed =
              field.substring(0, 6) === 'group[' ? field : field; // NOT UPPERCASING ANYMORE
          queryParams = queryParams.concat(`&${fieldTransformed}=${fields[field]}`);
      }
  }
 return queryParams;
};

/**
* Subscribe an email address to a Mailchimp email list.
* We use ES5 function syntax (instead of arrow) because we need `arguments.length`
*
* @param {String} email - required; the email address you want to subscribe
* @param {Object} fields - optional; add'l info (columns) you want included w/ this subscriber
* @param {String} endpointOverride - optional; if you want to override the default MC mailing list
*  that's listed in your gatsby-config, pass the list in here
*
* @return {Object} -
*  {
*    result: <String>(`success` || `error`)
*    msg: <String>(`Thank you for subscribing!` || `The email you entered is not valid.`),
*  }
*/
const addToMailchimp = function addToMailchimp(email, fields, endpointOverride) {
  //const isEmailValid = validate(email);
  const isEmailValid = true; // testing
  const emailEncoded = encodeURIComponent(email);
  if (!isEmailValid) {
      return Promise.resolve({
          result: 'error',
          msg: 'The email you entered is not valid.',
      });
 }

 let endpoint = 'https://johnforward1.us4.list-manage.com/subscribe/post?u=627d403ab5671dae64834341c&amp;id=5deb780900'; // eslint-disable-line no-undef
 const timeout = 3500; // eslint-disable-line no-undef

 // The following tests for whether you passed in a `fields` object. If
 // there are only two params and the second is a string, then we can safely
 // assume the second param is a MC mailing list, and not a fields object.
 if (arguments.length < 3 && typeof fields === 'string') {
     endpoint = fields;
 } else if (typeof endpointOverride === 'string') {
     endpoint = endpointOverride;
 }

 // Generates MC endpoint for our jsonp request. We have to
 // change `/post` to `/post-json` otherwise, MC returns an error
 endpoint = endpoint.replace(/\/post/g, '/post-json');
 const queryParams = `&EMAIL=${emailEncoded}${convertListFields(fields)}`;
 const url = `${endpoint}${queryParams}`;

 return subscribeEmailToMailchimp({ url, timeout });
};

const onFormSubmit = e => {
  e.preventDefault();

  let data = new FormData(e.target),
      obj = {};

  for (var key of data.keys()) {
    obj[key] = data.get(key);
  }

  let listFields = {
    FNAME: obj['FNAME'],
    LNAME: obj['LNAME'],
    EMAIL: obj['EMAIL'],
    PHONE: obj['PHONE'],
    BRTHDYMSG: obj['BRTHDYMSG'],
    tags: obj['tags']
  }

  addToMailchimp(obj['EMAIL'], listFields)
  .then(data => {
    // I recommend setting data to React state
    // but you can do whatever you want (including ignoring this `then()` altogether)

    let homePageHeroMailchimpForm = document.querySelector('.home-page-hero-mailchimp-form'),
        homePageHeroMailchimpSuccess = document.querySelector('.home-page-hero-mailchimp-success'),
        homePageHeroMailchimpAlreadySubscribed = document.querySelector('.home-page-hero-mailchimp-already-subscribed'),
        homePageHeroMailchimpError = document.querySelector('.home-page-hero-mailchimp-error');

    fadeOut(homePageHeroMailchimpForm);

    if (data.result === 'success') {
      fadeIn(homePageHeroMailchimpSuccess);

      navigate('https://secure.actblue.com/donate/john-goncalves-birthday-email?refcode=website-landing-birthday-email-hero');
    } else {
      if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
        fadeIn(homePageHeroMailchimpAlreadySubscribed);

        navigate('https://secure.actblue.com/donate/john-goncalves-birthday-email?refcode=website-landing-birthday-email-hero');
      } else {
        fadeIn(homePageHeroMailchimpError);
      }
    }
  })
  .catch(() => {
    // unnecessary because Mailchimp only ever
    // returns a 200 status code
    // see below for how to handle errors
  })
}

// fade out
const fadeOut = function fadeOut(el){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

// fade in
const fadeIn = function fadeIn(el, display){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    var proceed = ((val += 0.1) > 1) ? false : true;
    
    if (proceed) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

export default function BirthdayEmailHero() {
  return (
    <div className="flex flex-col-reverse lg:flex-col relative text-white">
      <div className="flex-none mx-auto max-w-7xl w-full pt-10 pb-10 text-center lg:text-left">
        <div className="px-4 xl:px-0 lg:w-1/2 xl:pr-16">
          <h1 className="text-3xl tracking-tight font-bold sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl">
            <span className="block xl:inline"><span className="text-aqua-headline">Sign this card to wish John Goncalves a happy birthday!</span></span>
          </h1>
          <div className="home-page-hero-mailchimp-form">
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <form
                className="mt-3 text-left"
                method="POST"
                action="#"
                onSubmit={onFormSubmit}
              >
                <input type="hidden" name="tags" value="4531383, 4531209, 4531427"></input> {/* Tags: RI-01 - John Goncalves for Congress, Website, Website - Landing - Birthday Email - Hero */}
                <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                  <div>
                    <label htmlFor="first-name" className="block text-base font-medium">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="FNAME"
                        id="first-name"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-base font-medium">
                      Last Name (optional)
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="LNAME"
                        id="last-name"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        maxLength="255"
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-base font-medium">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="EMAIL"
                        type="email"
                        autoComplete="email"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="tel" className="block text-base font-medium">
                      Mobile Phone Number (optional)
                    </label>
                    <div className="mt-1">
                      {/* TODO: validate this following https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel.*/ }
                      <input
                        id="tel"
                        name="PHONE"
                        type="tel"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        maxLength="255"
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="birthday-message" className="block text-base font-medium">
                      Send John a Special Birthday Message (optional) (max. length = 255 characters)
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        id="birthday-message"
                        name="BRTHDYMSG"
                        rows={4}
                        className="block w-full rounded-md border-0 px-4 py-3 text-black shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:border-blue-500 border-gray-300 sm:leading-6"
                        defaultValue={""}
                        maxLength="255"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-4 gap-x-4">
                  <div className="col-span-3 pt-6">
                    <button
                    type="submit"
                    className="py-3 w-full h-full border border-transparent text-lg font-medium rounded-md text-white bg-orange-button shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:items-center"
                    >
                      Sign this card!
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="home-page-hero-mailchimp-success hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Thank you for signing the card!
            </p>
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Make a donation to help elect John Goncalves! Every dollar fuels our grassroots campaign.

              Now redirecting you to a donation page...
            </p>
          </div>
          <div className="home-page-hero-mailchimp-already-subscribed hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Thank you for signing the card!
            </p>
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Make a donation to help elect John Goncalves! Every dollar fuels our grassroots campaign.

              Now redirecting you to a donation page...
            </p>
          </div>
          <div className="home-page-hero-mailchimp-error text-red hidden">
            <p className="max-w-md mx-auto text-lg sm:text-xl md:max-w-3xl">
              Error signing the card&mdash;please try again.
            </p>
          </div>
        </div>
      </div>
      <div className="flex-none relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={birthdayHeroJohnGoncalves}
          alt=""
        />
      </div>
    </div>
  )
}
